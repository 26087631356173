<template>
  <div class="d-block fill-height neutral">
    <logo-group
      class="px-6 py-6"
      :logomark="true"
      :logotype="true"
    />
    <v-container
      fluid
      class="white--text"
    >
      <v-container :class="{'py-0': $vuetify.breakpoint.smAndDown, 'py-5': $vuetify.breakpoint.mdAndUp}">
        <v-row
          class="align-center justify-center"
        >
          <v-col
            order="2"
            order-sm="1"
            cols="12"
            md="8"
          >
            <h1>{{ $t('Pages.Privacy.privacy_policy') }}</h1>
            <h3>{{ $t('Pages.Privacy.privacy_policy_since') }}</h3>
          </v-col>
          <v-col
            order="2"
            order-sm="1"
            cols="12"
            md="8"
          >
            <p style="text-align: justify;">
              {{ $t('Pages.Privacy.privacy_paragraph_first') }}
            </p>
            <p style="text-align: justify;">
              {{ $t('Pages.Privacy.privacy_paragraph_second') }}
              <a href="mailto:privacy@gigport.se">privacy@gigport.se</a>.
            </p>
            <h4 class="mb-4">
              {{ $t('Pages.Privacy.privacy_data_use') }}
            </h4>
            <TableComponent
              v-for="table in PrivacyTables.tables"
              :key="table.number"
              :headers="table.headers"
              :column-data="table.columnData"
              :table-footers="table.tableFooters"
              class="privacy-table"
            />
            <h4>{{ $t('Pages.Privacy.how_data_collection') }}</h4>
            <p style="text-align: justify;">
              {{ $t('Pages.Privacy.collect_data_by') }}:
              <ul style="padding-left: 40px; list-style-type: decimal;">
                <li
                  v-for="method in dataCollection"
                  :key="method"
                >
                  {{ $t(method) }}
                </li>
              </ul>
            </p>
            <h4>{{ $t('Pages.Privacy.recipients_personal_data') }}</h4>
            <p style="text-align: justify;">
              {{ $t('Pages.Privacy.recipients_personal_data_text') }}
            </p>
            <h4>{{ $t('Pages.Privacy.user_rights') }}</h4>
            <p style="text-align: justify;">
              {{ $t('Pages.Privacy.user_rights_to') }}:
              <ul style="padding-left: 40px; list-style-type: decimal;">
                <li
                  v-for="right in userRights"
                  :key="right"
                >
                  {{ $t(right) }}
                </li>
              </ul>
            </p>
            <h4>{{ $t('Pages.Privacy.policy_changes') }}</h4>
            <p style="text-align: justify;">
              {{ $t('Pages.Privacy.policy_changes_text') }}
            </p>
            <h4>{{ $t('Pages.Privacy.contact') }}</h4>
            <p>
              {{ $t('Pages.Privacy.contact_text') }}
              <a
                href="mailto:privacy@gigport.se"
                target="_blank"
                rel="noopener"
              >
                privacy@gigport.se
              </a>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import LogoGroup from '@/common/LogoGroup.vue'
import TableComponent from '@/common/TableComponent.vue'
import PrivacyTables from '@/config/privacyTables.js'
export default {
  name: 'Privacy',
  components: {
    TableComponent,
    LogoGroup
  },
  data () {
    return {
      PrivacyTables: PrivacyTables,
      userRights: [
        'Pages.Privacy.user_rights_to_request_info',
        'Pages.Privacy.user_rights_to_receive_data',
        'Pages.Privacy.user_rights_to_corrected_data',
        'Pages.Privacy.user_rights_to_restrict_data',
        'Pages.Privacy.user_rights_to_revoke_consent',
        'Pages.Privacy.user_rights_to_notify_authorities'
      ],
      dataCollection: [
        'Pages.Privacy.user_provided_data',
        'Pages.Privacy.user_provided_data_disc'
      ]
    }
  }
}
</script>
