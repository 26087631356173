<template>
  <table
    class="mb-4"
  >
    <tr>
      <th
        v-for="header in headers"
        :key="header.text"
        style="border: 1px solid #1a3d4f;"
      >
        {{ $t(header.text) }}
      </th>
    </tr>
    <tr>
      <td
        v-for="column in columnData"
        :key="column.name"
        style="border: 1px solid #1a3d4f;"
      >
        <ul
          v-if="column.list"
          style="padding-left: 20px; list-style-type: disc;"
        >
          <li
            v-for="item in column.list"
            :key="item.text"
          >
            {{ $t(item.text) }}
          </li>
        </ul>
        <span v-else>
          {{ $t(column.text) }}
        </span>
      </td>
    </tr>
    <tr
      v-for="footerItem in tableFooters"
      :key="footerItem.title"
    >
      <td :colspan="headers.length">
        <strong>{{ $t(footerItem.title) }}:</strong>
        <span>{{ $t(footerItem.text) }}</span>
      </td>
    </tr>
  </table>
</template>
<script>
export default {
  name: 'TableComponent',
  props: {
    headers: {
      type: Array,
      default: () => {}
    },
    columnData: {
      type: Array,
      default: () => {}
    },
    tableFooters: {
      type: Array,
      default: () => {}
    }
  },
  data () {
    return {

    }
  }
}
</script>
