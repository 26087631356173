var privacyTables = {
  tables: [
    {
      headers: [
        {
          text: 'Pages.Privacy.purpose'
        },
        {
          text: 'Pages.Privacy.processing_activities'
        },
        {
          text: 'Pages.Privacy.category_personal_data'
        }
      ],
      columnData: [
        {
          text: 'Pages.Privacy.purpose_c1_table_1'
        },
        {
          list: [
            {
              text: 'Pages.Privacy.processing_activities_c1_table_1'
            },
            {
              text: 'Pages.Privacy.processing_activities_c2_table_1'
            },
            {
              text: 'Pages.Privacy.processing_activities_c3_table_1'
            }
          ]
        },
        {
          list: [
            {
              text: 'Pages.Privacy.name'
            },
            {
              text: 'Pages.Privacy.userId_and_password'
            },
            {
              text: 'Pages.Privacy.personal_ID_number'
            },
            {
              text: 'Pages.Privacy.contact_info'
            },
            {
              text: 'Pages.Privacy.address'
            },
            {
              text: 'Pages.Privacy.resume'
            },
            {
              text: 'Pages.Privacy.information_in_settings'
            },
            {
              text: 'Pages.Privacy.privacy_disc'
            },
            {
              text: 'Pages.Privacy.drivers_license'
            },
            {
              text: 'Pages.Privacy.spoken_language'
            },
            {
              text: 'Pages.Privacy.skills'
            },
            {
              text: 'Pages.Privacy.experiences_workplace'
            },
            {
              text: 'Pages.Privacy.education'
            },
            {
              text: 'Pages.Privacy.company_conections'
            }
          ]
        }
      ],
      tableFooters: [
        {
          title: 'Pages.Privacy.legal_basis',
          text: 'Pages.Privacy.legal_basis_table_1_text'
        },
        {
          title: 'Pages.Privacy.retention_period',
          text: 'Pages.Privacy.retention_period_table_1_text'
        }
      ]
    },
    {
      headers: [
        {
          text: 'Pages.Privacy.purpose'
        },
        {
          text: 'Pages.Privacy.processing_activities'
        },
        {
          text: 'Pages.Privacy.category_personal_data'
        }
      ],
      columnData: [
        {
          text: 'Pages.Privacy.purpose_c1_table_2'
        },
        {
          list: [
            {
              text: 'Pages.Privacy.processing_activities_c1_table_2'
            },
            {
              text: 'Pages.Privacy.processing_activities_c2_table_2'
            }
          ]
        },
        {
          list: [
            {
              text: 'Pages.Privacy.name'
            },
            {
              text: 'Pages.Privacy.userId'
            },
            {
              text: 'Pages.Privacy.contact_info'
            },
            {
              text: 'Pages.Privacy.address'
            },
            {
              text: 'Pages.Privacy.password'
            },
            {
              text: 'Pages.Privacy.information_in_settings'
            },
            {
              text: 'Pages.Privacy.company_conections'
            }
          ]
        }
      ],
      tableFooters: [
        {
          title: 'Pages.Privacy.legal_basis',
          text: 'Pages.Privacy.legal_basis_table_1_text'
        },
        {
          title: 'Pages.Privacy.retention_period',
          text: 'Pages.Privacy.retention_period_table_1_text'
        }
      ]
    },
    {
      headers: [
        {
          text: 'Pages.Privacy.purpose'
        },
        {
          text: 'Pages.Privacy.processing_activities'
        },
        {
          text: 'Pages.Privacy.category_personal_data'
        }
      ],
      columnData: [
        {
          text: 'Pages.Privacy.purpose_c1_table_2B'
        },
        {
          list: [
            {
              text: 'Pages.Privacy.processing_activities_c1_table_2B'
            }
          ]
        },
        {
          list: [
            {
              text: 'Pages.Privacy.name'
            },
            {
              text: 'Pages.Privacy.userId'
            },
            {
              text: 'Pages.Privacy.contact_info'
            },
            {
              text: 'Pages.Privacy.address'
            },
            {
              text: 'Pages.Privacy.password'
            },
            {
              text: 'Pages.Privacy.information_in_settings'
            },
            {
              text: 'Pages.Privacy.company_conections'
            }
          ]
        }
      ],
      tableFooters: [
        {
          title: 'Pages.Privacy.legal_basis',
          text: 'Pages.Privacy.legal_basis_table_1_text'
        },
        {
          title: 'Pages.Privacy.retention_period',
          text: 'Pages.Privacy.retention_period_table_1_text'
        }
      ]
    },
    {
      headers: [
        {
          text: 'Pages.Privacy.purpose'
        },
        {
          text: 'Pages.Privacy.processing_activities'
        },
        {
          text: 'Pages.Privacy.category_personal_data'
        }
      ],
      columnData: [
        {
          text: 'Pages.Privacy.purpose_c1_table_3'
        },
        {
          list: [
            {
              text: 'Pages.Privacy.processing_activities_c1_table_3'
            }
          ]
        },
        {
          list: [
            {
              text: 'Pages.Privacy.name'
            },
            {
              text: 'Pages.Privacy.contact_info'
            }
          ]
        }
      ],
      tableFooters: [
        {
          title: 'Pages.Privacy.legal_basis',
          text: 'Pages.Privacy.legal_basis_table_3_text'
        },
        {
          title: 'Pages.Privacy.retention_period',
          text: 'Pages.Privacy.retention_period_table_3_text'
        }
      ]
    },
    {
      headers: [
        {
          text: 'Pages.Privacy.purpose'
        },
        {
          text: 'Pages.Privacy.processing_activities'
        },
        {
          text: 'Pages.Privacy.category_personal_data'
        }
      ],
      columnData: [
        {
          text: 'Pages.Privacy.purpose_c1_table_4'
        },
        {
          list: [
            {
              text: 'Pages.Privacy.processing_activities_c1_table_4'
            }
          ]
        },
        {
          list: [
            {
              text: 'Pages.Privacy.name'
            },
            {
              text: 'Pages.Privacy.userId'
            },
            {
              text: 'Pages.Privacy.contact_info'
            },
            {
              text: 'Pages.Privacy.address'
            }
          ]
        }
      ],
      tableFooters: [
        {
          title: 'Pages.Privacy.legal_basis',
          text: 'Pages.Privacy.legal_basis_table_4_text'
        },
        {
          title: 'Pages.Privacy.retention_period',
          text: 'Pages.Privacy.retention_period_table_4_text'
        }
      ]
    },
    {
      headers: [
        {
          text: 'Pages.Privacy.purpose'
        },
        {
          text: 'Pages.Privacy.processing_activities'
        },
        {
          text: 'Pages.Privacy.category_personal_data'
        }
      ],
      columnData: [
        {
          text: 'Pages.Privacy.purpose_c1_table_5'
        },
        {
          list: [
            {
              text: 'Pages.Privacy.processing_activities_c1_table_5'
            }
          ]
        },
        {
          list: [
            {
              text: 'Pages.Privacy.name'
            },
            {
              text: 'Pages.Privacy.userId'
            },
            {
              text: 'Pages.Privacy.personal_identification_number'
            },
            {
              text: 'Pages.Privacy.contact_info'
            },
            {
              text: 'Pages.Privacy.address'
            }
          ]
        }
      ],
      tableFooters: [
        {
          title: 'Pages.Privacy.legal_basis',
          text: 'Pages.Privacy.legal_basis_table_5_text'
        },
        {
          title: 'Pages.Privacy.retention_period',
          text: 'Pages.Privacy.retention_period_table_5_text'
        }
      ]
    }
  ]
}

export default privacyTables
